<script setup>
import { ref, defineAsyncComponent } from 'vue'
// import { useAuthStore } from '@/store/auth'

const perubahanharga = defineAsyncComponent(() =>
  import('@/components/laporan/stock/PerubahanHarga.vue')
)

const kartustock = defineAsyncComponent(() =>
  import('@/components/laporan/stock/KartuStock.vue')
)

const inventorysupplier = defineAsyncComponent(() =>
  import('@/components/laporan/stock/SupplierStock.vue')
)

const historyhargaitem = defineAsyncComponent(() =>
  import('@/components/laporan/stock/HistoryHargaItem.vue')
)

// const auth = useAuthStore()

let laporan = ref('kartustock')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Kartu Stock',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'kartustock'
    },
    visible: true, //auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Perubahan Harga',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'perubahanharga'
    },
    visible: true, //auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Inventory per Supplier',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'inventorysupplier'
    },
    visible: true, //auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan History Harga Jual per Barang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'historyhargaitem'
    },
    visible: true, //auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Inventory</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Laporan / Inventory</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <perubahanharga
            v-if="laporan === 'perubahanharga'"
            @toggle="onToggle"
          />
          <kartustock v-if="laporan === 'kartustock'" @toggle="onToggle" />
          <inventorysupplier
            v-if="laporan === 'inventorysupplier'"
            @toggle="onToggle"
          />
          <historyhargaitem
            v-if="laporan === 'historyhargaitem'"
            @toggle="onToggle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
